<template>
  <div>
    <Modal class="pop-inquiry-mobile" footer-hide v-model="showInquiry">
      <div class="top">
        <div class="product">
          {{ inquiry.product_name }}<span>[SOURCING]</span>
        </div>
        <div class="status status-progress" v-if="inquiry.status == 1">
          Active
        </div>
        <div class="status status-completed" v-if="inquiry.status == 2">
          Completed
        </div>
        <div class="status status-expired" v-if="inquiry.status == 3">
          Expired
        </div>
      </div>
      <div class="content">
        <div class="line-item">
          <div class="item">
            <div class="label">Quantity</div>
            <div class="value" :title="inquiry.quantity">
              {{ inquiry.quantity || "--" }}
            </div>
          </div>

          <div class="item">
            <div class="label">Delivery Address</div>
            <div class="value" :title="inquiry.delivery_address">
              {{ inquiry.delivery_address || "--" }}
            </div>
          </div>
          <div class="item">
            <div class="label">Basis</div>
            <div class="value" :title="inquiry.basis">
              {{ inquiry.basis || "--" }}
            </div>
          </div>
        </div>
        <div class="line-item">
          <div class="item">
            <div class="label">Specification</div>
            <div class="value" :title="inquiry.quality_standard">
              {{ inquiry.quality_standard || "--" }}
            </div>
          </div>
          <div class="item">
            <div class="label">Packaging</div>
            <div class="value" :title="inquiry.packaging">
              {{ inquiry.packaging || "--" }}
            </div>
          </div>
          <div class="item">
            <div class="label">Required Documents</div>
            <div class="value search-button">
              <template v-if="inquiry.coa.length > 0">
                <a :href="inquiry.coa[0].url" target="_blank">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.68 13.89C23.1338 9.5875 19.45 6.25 15 6.25C11.555 6.25 8.5625 8.2625 7.19625 11.4363C4.51125 12.24 2.5 14.775 2.5 17.5C2.5 20.8213 5.11125 23.5188 8.385 23.7138V23.75H22.4875V23.7462L22.5 23.75C25.2575 23.75 27.5 21.5075 27.5 18.75C27.4985 17.6294 27.1214 16.5416 26.4289 15.6606C25.7364 14.7796 24.7685 14.1562 23.68 13.89V13.89ZM10 15H13.75V11.25H16.25V15H20L15 21.25L10 15Z"
                      fill="#F57F45"
                    />
                  </svg>
                </a>
              </template>
              <template v-else>--</template>
            </div>
          </div>
        </div>

        <div class="line-item">
          <div class="item">
            <div class="label">Delivery Date</div>
            <div class="value">{{ inquiry.delivery_date | sliceDate }}</div>
          </div>
          <div class="item">
            <div class="label">Application Industry</div>
            <div class="value" :title="inquiry.application">
              {{ inquiry.application || "--" }}
            </div>
          </div>
          <div class="item">
            <div class="label">Sample Request</div>
            <div class="value">
              {{ inquiry.sample_request === 1 ? "Yes" : "No" }}
            </div>
          </div>
        </div>
        <div class="line-item">
          <div class="item">
            <div class="label">Estimated Annual Quantity</div>
            <div class="value">
              {{ inquiry.estimated_annual_quantity || "--" }}
            </div>
          </div>
          <div class="item">
            <div class="label">Type</div>
            <div class="value">{{ inquiry.sourcing_type || "--" }}</div>
          </div>
          <div class="item">
            <div class="label">Note</div>
            <div class="value" :title="inquiry.note">
              {{ inquiry.note || "--" }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="note item"
        v-if="
          inquiry.is_involved !== 1 &&
            !inquiry.is_my_inquiry &&
            inquiry.status === 1
        "
      >
        <div class="input">
          <div class="label">
            Note
          </div>
          <div class="value value-c">
            <input
              type="text"
              v-model="inquiryForm.note"
              placeholder="Leave a message"
            />
          </div>
        </div>
      </div>
      <div class="info" v-if="showInfo">
        <div class="warning" v-if="this.infoParam.user == ' '">
          The other company has hidden the information, please wait for the
          other company to contact you.
        </div>
        <div class="info-title">Contact Details:</div>
        <div class="info-content">
          <div class="left">
            <div class="item">
              <div class="label">Company :</div>
              <div
                class="value value-company search-button"
                :title="infoParam.company_name"
                v-if="infoParam.website"
              >
                <a
                  :href="infoParam.website"
                  target="_blank"
                  style="color: #f57f45;"
                >
                  {{ infoParam.company_name }}
                </a>
              </div>
              <div
                class="value search-button"
                :title="infoParam.company_name"
                v-else
              >
                {{ infoParam.company_name }}
              </div>
            </div>
            <div class="item" v-if="!showHiddenInfo">
              <div class="label">Contact Name :</div>
              <div class="value value-user" :title="infoParam.user">
                <div class="user">
                  {{ infoParam.user }}
                </div>
              </div>
            </div>
            <div class="item" v-if="!showHiddenInfo">
              <div class="label">Email :</div>
              <div class="value" :title="infoParam.email">
                {{ infoParam.email || "--" }}
              </div>
            </div>
            <div class="item" v-if="!showHiddenInfo">
              <div class="label">Phone Number :</div>
              <div class="value" :title="infoParam.phone">
                {{ infoParam.phone || "--" }}
              </div>
            </div>
          </div>
          <!--          <div class="right" v-if="!showHiddenInfo">-->
          <!--            <div class="avatar">-->
          <!--              <img :src="infoParam.userAvatar" alt="" />-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
        <div class="more-complete">
          <div
            class="more search-button"
            @click="
              viewCompanyQuotation(infoParam.company_id, infoParam.company_name)
            "
          >
            <div class="word">More Products</div>
            <div class="icon">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.2929 12H4.5C4.22386 12 4 11.7761 4 11.5C4 11.2239 4.22386 11 4.5 11H14.2929L11.1464 7.85355C10.9512 7.65829 10.9512 7.34171 11.1464 7.14645C11.3417 6.95118 11.6583 6.95118 11.8536 7.14645L15.8536 11.1464C16.0488 11.3417 16.0488 11.6583 15.8536 11.8536L11.8536 15.8536C11.6583 16.0488 11.3417 16.0488 11.1464 15.8536C10.9512 15.6583 10.9512 15.3417 11.1464 15.1464L14.2929 12Z"
                  fill="#f57f45"
                />
              </svg>
            </div>
          </div>
          <div class="complete search-button" @click="getImages(infoParam)">
            <div class="word">Complete List</div>
            <div class="icon">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.2929 12H4.5C4.22386 12 4 11.7761 4 11.5C4 11.2239 4.22386 11 4.5 11H14.2929L11.1464 7.85355C10.9512 7.65829 10.9512 7.34171 11.1464 7.14645C11.3417 6.95118 11.6583 6.95118 11.8536 7.14645L15.8536 11.1464C16.0488 11.3417 16.0488 11.6583 15.8536 11.8536L11.8536 15.8536C11.6583 16.0488 11.3417 16.0488 11.1464 15.8536C10.9512 15.6583 10.9512 15.3417 11.1464 15.1464L14.2929 12Z"
                  fill="#f57f45"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <!--        <div class="left">-->
        <!--          <div-->
        <!--            class="alert"-->
        <!--            v-if="-->
        <!--              !showInfo &&-->
        <!--                !inquiry.is_my_inquiry &&-->
        <!--                (inquiry.is_company_involved !== 1 || inquiry.hidden_company)-->
        <!--            "-->
        <!--          >-->
        <!--            <div class="right" v-if="inquiry.hidden_company === 1">-->
        <!--              After clicking the submit button, the buyer will receive your-->
        <!--              contact information.-->
        <!--            </div>-->
        <!--            <div class="right" v-else>-->
        <!--              After clicking the submit button, you will receive the buyer's-->
        <!--              contact information.-->
        <!--            </div>-->
        <!--            <div class="left">-->
        <!--              <svg-->
        <!--                width="8"-->
        <!--                height="48"-->
        <!--                viewBox="0 0 8 48"-->
        <!--                fill="none"-->
        <!--                xmlns="http://www.w3.org/2000/svg"-->
        <!--              >-->
        <!--                <path-->
        <!--                  d="M1.43051e-06 16L0 32L8 24L1.43051e-06 16Z"-->
        <!--                  fill="#373D3F"-->
        <!--                />-->
        <!--              </svg>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="right">
          <template v-if="inquiry.status === 1">
            <div
              class="button"
              v-if="inquiry.is_involved !== 1 && !inquiry.is_my_inquiry"
            >
              <!--              <div class="cancel search-button" @click="cancel">Cancel</div>-->
              <div class="save search-button" @click="save" v-if="!btnLoading">
                Submit
              </div>
              <div class="save search-button loading-button" v-else>
                <img src="@/assets/loading.gif" />
              </div>
              <div
                class="add search-button"
                @click="addCollection"
                v-if="inquiry.is_pending_submit === 0"
              >
                Add To Submit List
              </div>
              <div class="already search-button" @click="viewCollection" v-else>
                View In Submit List
              </div>
            </div>
            <div class="button" v-else>
              <!--              <div class="expired-cancel search-button" @click="cancel">-->
              <!--                Cancel-->
              <!--              </div>-->
            </div>
          </template>
          <template v-if="inquiry.status === 2">
            <div class="content"></div>
            <div class="button">
              <!--              <div class="expired-cancel search-button" @click="cancel">-->
              <!--                Cancel-->
              <!--              </div>-->
            </div>
          </template>
          <template v-if="inquiry.status > 2">
            <div class="button">
              <!--              <div class="expired-cancel search-button" @click="cancel">-->
              <!--                Cancel-->
              <!--              </div>-->
            </div>
          </template>
        </div>
      </div>
    </Modal>
    <quotationImages ref="quotationImages"></quotationImages>
    <isGotoCollection ref="isGotoCollection"></isGotoCollection>
  </div>
</template>

<script>
import api from "@/api";
const { _quoteInquiry, _storeTradeCollection } = api;
import { mapState } from "vuex";
import quotationImages from "@/components/quotationImages";
import isGotoCollection from "@/components/isGotoCollection";

export default {
  props: [],
  computed: {
    ...mapState("user", ["role", "company_id"])
  },
  components: { quotationImages, isGotoCollection },

  data() {
    return {
      inquiryForm: { price: "", note: "", id: "" },
      showInquiry: false,
      loading: false,
      showInfo: false,
      infoParam: {
        company_name: "",
        user: "",
        email: "",
        phone: "",
        website: ""
      },
      btnLoading: false,
      // inquiry: this.inquiry,
      inquiry: {},
      showHiddenInfo: false
    };
  },
  mounted() {},
  watch: {
    inquiry(newData) {
      if (newData.is_my_inquiry || newData.is_involved) {
        this.showInfo = true;
        this.showHiddenInfo = false;
        this.infoParam.company_id = newData.company.id;
        this.infoParam.company_name = newData.company.company_name;
        this.infoParam.user =
          newData.user.first_name + " " + newData.user.last_name;
        this.infoParam.email = newData.user.email;
        this.infoParam.phone = newData.user.phone;
        this.infoParam.website = newData.company.website;
        this.infoParam.userAvatar = newData.user.avatar.url;
      } else {
        this.showInfo = false;
        this.infoParam = {
          company_name: newData.company.company_name,
          user: " ",
          email: " ",
          company_id: newData.company.id,
          phone: "",
          website: ""
        };
      }
      this.infoParam.company_id = newData.company.id;
      this.infoParam.company_name = newData.company.company_name;
    }
  },
  filters: {
    sliceDate(val) {
      if (val) {
        return val.slice(0, 10);
      } else {
        return null;
      }
    }
  },
  methods: {
    viewCollection() {
      this.$router.push("/trade-collection");
    },
    addCollection() {
      if (this.role === "") {
        this.loading = false;
        this.btnLoading = false;
        this.$Message.error("Please log in first");
        return;
      }
      _storeTradeCollection({
        id: this.inquiry.id
      })
        .then(response => {
          console.log(response);
          // this.$Message.success("success");
          this.$refs.isGotoCollection.showGuide = true;
          this.$emit("getSourcings");
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.showInquiry = false;
        });
    },
    getImages(item) {
      this.$refs.quotationImages.showUpload = true;
      this.$refs.quotationImages.companyId = item.company_id;
      this.$refs.quotationImages.companyName = item.company_name;
    },
    changeBasis(basis) {
      this.editInquiryForm.basis === basis
        ? (this.editInquiryForm.basis = "")
        : (this.editInquiryForm.basis = basis);
      console.log(this.editInquiryForm.basis);
    },
    viewCompanyQuotation(id, name) {
      console.log(id);
      const str = Math.random()
        .toString(36)
        .substr(2, 10);
      var data = str + id;
      var hashId = window.btoa(data);
      if ("/trades" === this.$route.path) {
        this.$emit("getAllCompany", [hashId, name]);
      } else {
        this.$router.push(
          "/maxsource?company=" +
            hashId +
            "&search_type=" +
            "1&hc=n&company_name=" +
            name
        );
      }
    },
    cancel() {
      this.showInquiry = false;
    },
    save() {
      this.loading = true;
      this.btnLoading = true;
      if (this.role === "") {
        this.loading = false;
        this.btnLoading = false;
        this.$Message.error("Please log in first");
        return;
      }
      this.inquiryForm.id = this.inquiry.id;
      _quoteInquiry({ ...this.inquiryForm })
        .then(data => {
          this.$Message.success("success");
          this.$emit("getSourcings");
          if (this.inquiry.hidden_company === 0) {
            this.showInfo = true;
            this.infoParam.company_id = data.data.company.id;
            this.infoParam.company_name = data.data.company.company_name;
            this.infoParam.user =
              data.data.user.first_name + " " + data.data.user.last_name;
            this.infoParam.email = data.data.user.email;
            this.infoParam.phone = data.data.user.phone;
            this.infoParam.website = data.data.company.website;
            this.infoParam.userAvatar = data.data.user.avatar.url;
          } else {
            this.showInfo = true;
            this.infoParam.company_name = data.data.company.company_name;
            this.showHiddenInfo = true;
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.btnLoading = false;
          this.inquiry.is_involved = 1;
          // this.loading = false;
          // this.showInquiry = false;
          // this.inquiryForm.price = "";
          this.inquiryForm.note = "";
          // this.showInfo = true;
        });
    }
  }
};
</script>
<style lang="less" scoped>
.pop-inquiry-mobile {
  padding: 30px;
  .top {
    /*display: flex;*/
    /*justify-content: space-between;*/
    margin-top: 20px;
    margin-bottom: 20px;
    .product {
      font-size: 20px;
      font-weight: 600;
      color: #222;
      max-width: 430px;
      word-break: break-all;
      span {
        color: #939393;
        font-size: 16px;
        font-weight: 600;
        margin-left: 5px;
      }
    }
    .status {
      width: 90px;
      height: 30px;
      border-radius: 5px;
      display: flex;
      justify-content: center !important;
      color: #fff;
      font-size: 13px;
      align-items: center;
    }
    .status-progress {
      background: #11828f;
    }
    .status-expired {
      background: #c0399f;
    }
    .status-completed {
      background: #925acf;
    }
  }
  .content {
    .line-item {
      /*display: flex;*/
      /*justify-content: space-between;*/
      margin-bottom: 15px;
      .item {
        width: 100%;
        margin-top: 6px;

        .label {
          color: #8a8a8a;
          font-size: 14px;
          padding-bottom: 3px;
        }
        .value {
          color: #646464;
          font-size: 15px;
          font-weight: 600;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  .button {
    /*display: flex;*/
    /*justify-content: right;*/
    margin-top: 20px;
    .cancel {
      color: #989696;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
    }
    .add {
      color: #f57f45;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      border: 1px solid #f57f45;
      border-radius: 20px;
      margin-left: 10px;
      padding-left: 10px;
      padding-right: 10px;
      width: 200px;
      height: 42px;
      margin: 10px auto 0 auto;
    }
    .already {
      color: #b5b4b4;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      border: 1px solid #b5b4b4;
      border-radius: 20px;
      margin-left: 10px;
      padding-left: 10px;
      padding-right: 10px;
      width: 215px;
      height: 42px;
      margin: 10px auto 0 auto;
    }
    .expired-cancel {
      background: #bababa;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      color: #fff;
      padding: 5px 10px 5px 10px;
      border-radius: 5px;
    }
    .save {
      background: #f57f45;
      color: #fff;
      /*width: 82px;*/
      height: 42px;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      width: 200px;
    }
    .loading-button {
      opacity: 0.8;
      img {
        width: 18px;
      }
    }
  }
}
.input-value {
  margin-bottom: 15px;
  .label {
    color: #8a8a8a;
    font-size: 14px;
    padding-bottom: 3px;
  }
  .input {
    input {
      border: 2px solid #dfdfe6;
      outline: none;
      width: 200px;
      height: 40px;
      border-radius: 12px;
      padding-left: 10px;
      margin-top: 10px;
    }
    input:focus {
      border: 2px solid #f57f45;
    }
  }
  .textarea {
    textarea {
      border: 1px solid #c1c1c1;
      outline: none;
      width: 200px;
      border-radius: 5px;
      padding-left: 10px;
    }
  }
}

.company {
  background: #f2f2f2;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 30px;
  .top {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #cccccc;
    margin-top: 5px;
    padding-bottom: 10px;
    .icon {
      position: relative;
      top: 4px;
      right: 5px;
    }
    .name {
      font-size: 18px;
      font-weight: 600;
    }
  }
  .info {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    .item {
      width: 33%;
      .label {
        font-size: 14px;
        color: #8a8a8a;
        padding-left: 20px;
      }
      .value {
        font-size: 16px;
        color: #000;
        font-weight: 600;
        /*white-space: nowrap;*/
        /*overflow: hidden;*/
        /*text-overflow: ellipsis;*/
        padding-left: 20px;
        word-wrap: break-word;
        max-width: 200px;
        word-break: break-all;
      }
      .user {
        word-break: break-all;
      }
      .show-value {
        white-space: normal !important;
        word-wrap: break-word;
      }
    }
  }
}
.info {
  background: #ebebeb;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 15px;
  .info-title {
    color: #000;
    font-size: 16px;
    font-weight: 700;
    margin: 10px 0 10px 0;
  }
  .warning {
    font-size: 14px;
    color: #ff5400;
  }
  .info-content {
    display: flex;
    justify-content: space-between;
    .right .avatar {
      margin: 0 50px 0 0;
      img {
        height: 80px;
        width: 80px;
        border-radius: 10px;
      }
    }
    .item {
      display: flex;
      margin-bottom: 10px;
      .label {
        margin-right: 5px;
        width: 150px;
        font-size: 15px;
        text-align: right;
      }
      .value {
        font-weight: 600;
      }
      .value-user {
        display: flex;
        .avatar {
          margin-right: 10px;
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}
.more-complete {
  display: flex;
  justify-content: right;
  font-size: 11px;
  .more {
    display: flex;
    margin-right: 20px;
    .word {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #f57f45;
      height: 100%;
      font-size: 13px;
    }
    .icon {
      padding-top: 7px;
    }
  }
  .complete {
    display: flex;
    .word {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #f57f45;
      height: 100%;
      font-size: 13px;
    }
    .icon {
      padding-top: 7px;
    }
  }
}
.bottom {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  .left {
    margin-left: 30px;
  }
  .alert {
    display: flex;
    .right {
      width: 350px;
      font-size: 14px;
      height: 56px;

      /* Grays/Casper/CA-700 */

      background: #334155;
      border-radius: 8px;

      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 1;
      color: #fff;
      display: flex;
      justify-content: left;
      align-items: center;
      padding: 10px;
    }
    .left {
      margin-left: 0;
    }
  }
}
.note {
  margin-bottom: 15px;
  .input .label {
    color: #87898e;
    font-size: 16px;
    margin-bottom: 12px;
  }
  .input .value input {
    border-radius: 12px;
    height: 42px;
    border: 2px solid #dfdfe6;
    outline: none;
    width: 300px;
    padding-left: 10px;
  }
}
</style>

<style lang="less">
.pop-inquiry-mobile {
  .ivu-modal {
    width: 95% !important;
    margin: 0 auto !important;
    .ivu-modal-content {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
</style>
