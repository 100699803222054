<template>
  <div>
    <Modal class="pop-pricing" footer-hide v-model="showPricing">
      <div class="top">Choose your Plan</div>
      <div class="content">
        <div class="left item">
          <div class="price-date">
            <div class="price">$0</div>
            <div class="date">/month</div>
          </div>
          <div class="type">Free Trial</div>
          <div class="benefits">
            <div class="benefit">
              <div class="icon">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C19.9936 4.47982 15.5202 0.00642897 10 0Z"
                    fill="#5243C2"
                    fill-opacity="0.103693"
                  />
                  <path
                    d="M15.7741 6.83362L10.07 14.5745C9.93392 14.7549 9.73107 14.8732 9.50698 14.9027C9.28289 14.9321 9.05636 14.8703 8.8783 14.7311L4.80496 11.4745C4.44552 11.1868 4.38731 10.6622 4.67496 10.3028C4.96261 9.94334 5.48718 9.88514 5.84663 10.1728L9.2433 12.8903L14.4325 5.84778C14.6026 5.59244 14.8993 5.45127 15.2048 5.48032C15.5103 5.50936 15.7751 5.70393 15.8941 5.98676C16.013 6.2696 15.967 6.59494 15.7741 6.83362Z"
                    fill="#5243C2"
                  />
                </svg>
              </div>
              <div class="word">Search by products</div>
            </div>
            <div class="benefit">
              <div class="icon">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C19.9936 4.47982 15.5202 0.00642897 10 0Z"
                    fill="#5243C2"
                    fill-opacity="0.103693"
                  />
                  <path
                    d="M15.7741 6.83362L10.07 14.5745C9.93392 14.7549 9.73107 14.8732 9.50698 14.9027C9.28289 14.9321 9.05636 14.8703 8.8783 14.7311L4.80496 11.4745C4.44552 11.1868 4.38731 10.6622 4.67496 10.3028C4.96261 9.94334 5.48718 9.88514 5.84663 10.1728L9.2433 12.8903L14.4325 5.84778C14.6026 5.59244 14.8993 5.45127 15.2048 5.48032C15.5103 5.50936 15.7751 5.70393 15.8941 5.98676C16.013 6.2696 15.967 6.59494 15.7741 6.83362Z"
                    fill="#5243C2"
                  />
                </svg>
              </div>
              <div class="word">Limited inquiry</div>
            </div>
            <div class="benefit">
              <div class="icon">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C19.9936 4.47982 15.5202 0.00642897 10 0Z"
                    fill="#5243C2"
                    fill-opacity="0.103693"
                  />
                  <path
                    d="M15.7741 6.83362L10.07 14.5745C9.93392 14.7549 9.73107 14.8732 9.50698 14.9027C9.28289 14.9321 9.05636 14.8703 8.8783 14.7311L4.80496 11.4745C4.44552 11.1868 4.38731 10.6622 4.67496 10.3028C4.96261 9.94334 5.48718 9.88514 5.84663 10.1728L9.2433 12.8903L14.4325 5.84778C14.6026 5.59244 14.8993 5.45127 15.2048 5.48032C15.5103 5.50936 15.7751 5.70393 15.8941 5.98676C16.013 6.2696 15.967 6.59494 15.7741 6.83362Z"
                    fill="#5243C2"
                  />
                </svg>
              </div>
              <div class="word">Limited inquiryOwn storefront</div>
            </div>
          </div>
          <div class="button search-button">
            Select Plan
          </div>
        </div>
        <div class="mid item">
          <div class="popular">
            MOST POPULAR
          </div>
          <div class="price-date">
            <div class="price">$199</div>
            <div class="date">/year</div>
          </div>
          <!--          <div class="img">-->
          <!--            <img src="@/assets/data-search/pricing.png" alt=""/>-->
          <!--          </div>-->
          <div class="type">Annual Plan</div>
          <div class="benefits">
            <div class="benefit">
              <div class="icon">
                <svg
                  width="19"
                  height="20"
                  viewBox="0 0 19 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.1"
                    d="M9 0C3.47715 0 -1 4.47715 -1 10C-1 15.5228 3.47715 20 9 20C14.5228 20 19 15.5228 19 10C18.9936 4.47982 14.5202 0.00642897 9 0Z"
                    fill="white"
                  />
                  <path
                    d="M14.7725 6.83337L9.06831 14.5742C8.93228 14.7547 8.72942 14.873 8.50533 14.9024C8.28124 14.9319 8.05472 14.8701 7.87665 14.7309L3.80331 11.4742C3.44387 11.1866 3.38567 10.662 3.67331 10.3025C3.96096 9.94309 4.48554 9.88489 4.84498 10.1725L8.24165 12.89L13.4308 5.84754C13.601 5.5922 13.8977 5.45103 14.2032 5.48007C14.5086 5.50912 14.7734 5.70368 14.8924 5.98652C15.0114 6.26935 14.9653 6.59469 14.7725 6.83337Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div class="word">Search by products</div>
            </div>
            <div class="benefit">
              <div class="icon">
                <svg
                  width="19"
                  height="20"
                  viewBox="0 0 19 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.1"
                    d="M9 0C3.47715 0 -1 4.47715 -1 10C-1 15.5228 3.47715 20 9 20C14.5228 20 19 15.5228 19 10C18.9936 4.47982 14.5202 0.00642897 9 0Z"
                    fill="white"
                  />
                  <path
                    d="M14.7725 6.83337L9.06831 14.5742C8.93228 14.7547 8.72942 14.873 8.50533 14.9024C8.28124 14.9319 8.05472 14.8701 7.87665 14.7309L3.80331 11.4742C3.44387 11.1866 3.38567 10.662 3.67331 10.3025C3.96096 9.94309 4.48554 9.88489 4.84498 10.1725L8.24165 12.89L13.4308 5.84754C13.601 5.5922 13.8977 5.45103 14.2032 5.48007C14.5086 5.50912 14.7734 5.70368 14.8924 5.98652C15.0114 6.26935 14.9653 6.59469 14.7725 6.83337Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div class="word">Unlimited inquiries</div>
            </div>
            <div class="benefit">
              <div class="icon">
                <svg
                  width="19"
                  height="20"
                  viewBox="0 0 19 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.1"
                    d="M9 0C3.47715 0 -1 4.47715 -1 10C-1 15.5228 3.47715 20 9 20C14.5228 20 19 15.5228 19 10C18.9936 4.47982 14.5202 0.00642897 9 0Z"
                    fill="white"
                  />
                  <path
                    d="M14.7725 6.83337L9.06831 14.5742C8.93228 14.7547 8.72942 14.873 8.50533 14.9024C8.28124 14.9319 8.05472 14.8701 7.87665 14.7309L3.80331 11.4742C3.44387 11.1866 3.38567 10.662 3.67331 10.3025C3.96096 9.94309 4.48554 9.88489 4.84498 10.1725L8.24165 12.89L13.4308 5.84754C13.601 5.5922 13.8977 5.45103 14.2032 5.48007C14.5086 5.50912 14.7734 5.70368 14.8924 5.98652C15.0114 6.26935 14.9653 6.59469 14.7725 6.83337Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div class="word">Own storefront</div>
            </div>
            <div class="benefit">
              <div class="icon">
                <svg
                  width="19"
                  height="20"
                  viewBox="0 0 19 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.1"
                    d="M9 0C3.47715 0 -1 4.47715 -1 10C-1 15.5228 3.47715 20 9 20C14.5228 20 19 15.5228 19 10C18.9936 4.47982 14.5202 0.00642897 9 0Z"
                    fill="white"
                  />
                  <path
                    d="M14.7725 6.83337L9.06831 14.5742C8.93228 14.7547 8.72942 14.873 8.50533 14.9024C8.28124 14.9319 8.05472 14.8701 7.87665 14.7309L3.80331 11.4742C3.44387 11.1866 3.38567 10.662 3.67331 10.3025C3.96096 9.94309 4.48554 9.88489 4.84498 10.1725L8.24165 12.89L13.4308 5.84754C13.601 5.5922 13.8977 5.45103 14.2032 5.48007C14.5086 5.50912 14.7734 5.70368 14.8924 5.98652C15.0114 6.26935 14.9653 6.59469 14.7725 6.83337Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div class="word">Unlimited contacts</div>
            </div>
            <div class="benefit">
              <div class="icon">
                <svg
                  width="19"
                  height="20"
                  viewBox="0 0 19 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.1"
                    d="M9 0C3.47715 0 -1 4.47715 -1 10C-1 15.5228 3.47715 20 9 20C14.5228 20 19 15.5228 19 10C18.9936 4.47982 14.5202 0.00642897 9 0Z"
                    fill="white"
                  />
                  <path
                    d="M14.7725 6.83337L9.06831 14.5742C8.93228 14.7547 8.72942 14.873 8.50533 14.9024C8.28124 14.9319 8.05472 14.8701 7.87665 14.7309L3.80331 11.4742C3.44387 11.1866 3.38567 10.662 3.67331 10.3025C3.96096 9.94309 4.48554 9.88489 4.84498 10.1725L8.24165 12.89L13.4308 5.84754C13.601 5.5922 13.8977 5.45103 14.2032 5.48007C14.5086 5.50912 14.7734 5.70368 14.8924 5.98652C15.0114 6.26935 14.9653 6.59469 14.7725 6.83337Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div class="word">Premium support</div>
            </div>
          </div>
          <div class="button search-button">
            Select Plan
          </div>
        </div>
        <div class="right item">
          <div class="price-date">
            <div class="price">$19</div>
            <div class="date">/month</div>
          </div>
          <div class="type">Monthly Plan</div>
          <div class="benefits">
            <div class="benefit">
              <div class="icon">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C19.9936 4.47982 15.5202 0.00642897 10 0Z"
                    fill="#5243C2"
                    fill-opacity="0.103693"
                  />
                  <path
                    d="M15.7741 6.83362L10.07 14.5745C9.93392 14.7549 9.73107 14.8732 9.50698 14.9027C9.28289 14.9321 9.05636 14.8703 8.8783 14.7311L4.80496 11.4745C4.44552 11.1868 4.38731 10.6622 4.67496 10.3028C4.96261 9.94334 5.48718 9.88514 5.84663 10.1728L9.2433 12.8903L14.4325 5.84778C14.6026 5.59244 14.8993 5.45127 15.2048 5.48032C15.5103 5.50936 15.7751 5.70393 15.8941 5.98676C16.013 6.2696 15.967 6.59494 15.7741 6.83362Z"
                    fill="#5243C2"
                  />
                </svg>
              </div>
              <div class="word">Search by products</div>
            </div>
            <div class="benefit">
              <div class="icon">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C19.9936 4.47982 15.5202 0.00642897 10 0Z"
                    fill="#5243C2"
                    fill-opacity="0.103693"
                  />
                  <path
                    d="M15.7741 6.83362L10.07 14.5745C9.93392 14.7549 9.73107 14.8732 9.50698 14.9027C9.28289 14.9321 9.05636 14.8703 8.8783 14.7311L4.80496 11.4745C4.44552 11.1868 4.38731 10.6622 4.67496 10.3028C4.96261 9.94334 5.48718 9.88514 5.84663 10.1728L9.2433 12.8903L14.4325 5.84778C14.6026 5.59244 14.8993 5.45127 15.2048 5.48032C15.5103 5.50936 15.7751 5.70393 15.8941 5.98676C16.013 6.2696 15.967 6.59494 15.7741 6.83362Z"
                    fill="#5243C2"
                  />
                </svg>
              </div>
              <div class="word">Unlimited inquiries</div>
            </div>
            <div class="benefit">
              <div class="icon">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C19.9936 4.47982 15.5202 0.00642897 10 0Z"
                    fill="#5243C2"
                    fill-opacity="0.103693"
                  />
                  <path
                    d="M15.7741 6.83362L10.07 14.5745C9.93392 14.7549 9.73107 14.8732 9.50698 14.9027C9.28289 14.9321 9.05636 14.8703 8.8783 14.7311L4.80496 11.4745C4.44552 11.1868 4.38731 10.6622 4.67496 10.3028C4.96261 9.94334 5.48718 9.88514 5.84663 10.1728L9.2433 12.8903L14.4325 5.84778C14.6026 5.59244 14.8993 5.45127 15.2048 5.48032C15.5103 5.50936 15.7751 5.70393 15.8941 5.98676C16.013 6.2696 15.967 6.59494 15.7741 6.83362Z"
                    fill="#5243C2"
                  />
                </svg>
              </div>
              <div class="word">Own storefront</div>
            </div>
            <div class="benefit">
              <div class="icon">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C19.9936 4.47982 15.5202 0.00642897 10 0Z"
                    fill="#5243C2"
                    fill-opacity="0.103693"
                  />
                  <path
                    d="M15.7741 6.83362L10.07 14.5745C9.93392 14.7549 9.73107 14.8732 9.50698 14.9027C9.28289 14.9321 9.05636 14.8703 8.8783 14.7311L4.80496 11.4745C4.44552 11.1868 4.38731 10.6622 4.67496 10.3028C4.96261 9.94334 5.48718 9.88514 5.84663 10.1728L9.2433 12.8903L14.4325 5.84778C14.6026 5.59244 14.8993 5.45127 15.2048 5.48032C15.5103 5.50936 15.7751 5.70393 15.8941 5.98676C16.013 6.2696 15.967 6.59494 15.7741 6.83362Z"
                    fill="#5243C2"
                  />
                </svg>
              </div>
              <div class="word">Unlimited contacts</div>
            </div>
            <div class="benefit">
              <div class="icon">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C19.9936 4.47982 15.5202 0.00642897 10 0Z"
                    fill="#5243C2"
                    fill-opacity="0.103693"
                  />
                  <path
                    d="M15.7741 6.83362L10.07 14.5745C9.93392 14.7549 9.73107 14.8732 9.50698 14.9027C9.28289 14.9321 9.05636 14.8703 8.8783 14.7311L4.80496 11.4745C4.44552 11.1868 4.38731 10.6622 4.67496 10.3028C4.96261 9.94334 5.48718 9.88514 5.84663 10.1728L9.2433 12.8903L14.4325 5.84778C14.6026 5.59244 14.8993 5.45127 15.2048 5.48032C15.5103 5.50936 15.7751 5.70393 15.8941 5.98676C16.013 6.2696 15.967 6.59494 15.7741 6.83362Z"
                    fill="#5243C2"
                  />
                </svg>
              </div>
              <div class="word">Premium support</div>
            </div>
          </div>
          <div class="button search-button">
            Select Plan
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["inquiry"],
  computed: {
    ...mapState("user", ["role"])
  },
  data() {
    return {
      showPricing: false,
      loading: false
      // inquriy: {}
    };
  },
  mounted() {},
  filters: {
    sliceDate(val) {
      if (val) {
        return val.slice(0, 10);
      } else {
        return null;
      }
    }
  },
  methods: {}
};
</script>
<style lang="less" scoped>
.pop-pricing {
  background: #f5f5fa;
  border-radius: 15px;
  .top {
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    text-align: center;

    color: #231d4f;
  }
  .content {
    display: flex;
    justify-content: center;
    .mid {
      margin-top: 30px !important;
      background: #5243c2 !important;
      box-shadow: 0px 42px 34px rgba(82, 67, 194, 0.295755);
      border-radius: 26px;
      .popular {
        display: flex;
        justify-content: center;
        color: #f496d1;
        border-radius: 13px;
        width: 130px;
        height: 27px;
        font-size: 12px;
        background: #4031b3;
        margin: 25px 0 0 90px;
        align-items: center;
      }
    }
    .item {
      margin-top: 110px;
      background: #fff;
      padding-left: 40px;
      width: 280px;
      padding-bottom: 30px;
      .price-date {
        display: flex;
        .price {
          font-weight: 500;
          font-size: 36px;
          line-height: 46px;
          /* identical to box height, or 128% */

          color: #231d4f;
        }
        .date {
          font-weight: 500;
          font-size: 17px;
          line-height: 21px;
          /* identical to box height */
          color: #848199;
          margin: 17px 0 0 10px;
        }
      }
      .type {
        /*display: flex;*/
        font-weight: 500;
        font-size: 23px;
        line-height: 32px;
        /* identical to box height */

        color: #231d4f;
        margin: 10px 0 10px 0;
      }
      .benefits {
        .benefit {
          display: flex;
          margin-bottom: 5px;
          .icon {
            margin: 3px 10px 0 0;
          }
          .word {
          }
        }
      }
      .button {
        background: #fbf3f8;
        color: #4031b3;
        height: 45px;
        width: 207px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 24px;
        font-size: 15px;
        font-weight: 600;
      }
    }
    .mid {
      margin-top: 30px;
      background: #5243c2;
      box-shadow: 0px 42px 34px rgba(82, 67, 194, 0.295755);
      border-radius: 26px;
      padding-bottom: 10px;
      height: 425px;
      .price-date {
        .price {
          color: #fff;
        }
        .date {
          color: #fff;
        }
      }
      .type {
        color: #fff;
      }
      .benefits {
        .benefit {
          .icon {
          }
          .word {
            color: #fff;
          }
        }
      }
      .button {
        margin-top: 20px;
      }
    }
    .left {
      padding-top: 20px;
      border-top-left-radius: 26px;
      border-bottom-left-radius: 26px;
      border-bottom-right-radius: 26px;
      .button {
        margin-top: 70px;
      }
    }
    .right {
      padding-top: 20px;
      border-top-right-radius: 26px;
      border-bottom-right-radius: 26px;
      border-bottom-left-radius: 26px;
      .button {
        margin-top: 20px;
      }
    }
  }
}
</style>

<style lang="less">
.pop-pricing {
  .ivu-modal {
    width: 900px !important;

    .ivu-modal-content {
      padding-left: 20px;
      padding-right: 20px;
      background: #f5f5fa;
      border-radius: 15px;
    }
  }
}
</style>
