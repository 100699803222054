<template>
  <div class="homePage">
    <div :class="['search', { 'not-init-search': !searchInit }]">
      <div class="top">
        <div class="icon">
          <svg
            width="18"
            height="17"
            viewBox="0 0 18 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.6771 16.607L9.9621 10.891C7.41977 12.6984 3.91654 12.2563 1.90298 9.87392C-0.110589 7.4915 0.0372615 3.96358 2.2431 1.75799C4.44836 -0.448564 7.97663 -0.597054 10.3595 1.4164C12.7424 3.42986 13.1847 6.93344 11.3771 9.47599L17.0921 15.192L15.6781 16.606L15.6771 16.607ZM6.4851 1.99997C4.5888 1.99955 2.95279 3.33066 2.56758 5.18742C2.18236 7.04418 3.15381 8.91626 4.89378 9.67023C6.63375 10.4242 8.664 9.85282 9.75535 8.30204C10.8467 6.75126 10.6992 4.64731 9.4021 3.26399L10.0071 3.86399L9.3251 3.18399L9.3131 3.17199C8.56489 2.41917 7.5465 1.99712 6.4851 1.99997Z"
              fill="#828282"
            />
          </svg>
        </div>
        <div class="input">
          <input
            placeholder=""
            v-model="s"
            @keyup.enter="getData"
            ref="searchInput"
            id="search-product"
            @input="inputSearch"
          />
          <!--          <label for="search-product" v-if="showLabel">{{searchProductLabel}}</label>-->
        </div>

        <div class="icon2 search-button" @click="clearSearch">
          <svg
            width="22"
            height="21"
            viewBox="0 0 22 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.0001 21.0001C6.95361 21.0024 3.30455 18.5659 1.75572 14.8276C0.206894 11.0893 1.0636 6.78609 3.92606 3.92601C6.45335 1.39871 10.137 0.411692 13.5893 1.33675C17.0417 2.2618 19.7383 4.9584 20.6633 8.41075C21.5884 11.8631 20.6014 15.5467 18.0741 18.074C16.2015 19.955 13.6542 21.0087 11.0001 21.0001ZM3.00006 11.172C3.04737 15.5733 6.64116 19.1096 11.0426 19.086C15.444 19.0622 18.9996 15.4875 18.9996 11.086C18.9996 6.68452 15.444 3.10978 11.0426 3.08601C6.64116 3.06247 3.04737 6.59877 3.00006 11V11.172ZM8.40906 15L7.00006 13.59L9.59006 11L7.00006 8.41001L8.41006 7.00001L11.0001 9.59001L13.5901 7.00001L15.0001 8.41001L12.4101 11L15.0001 13.59L13.5911 15L11.0001 12.41L8.41006 15H8.40906Z"
              fill="#BDBDBD"
            />
          </svg>
        </div>
        <div
          :class="[
            'button',
            'font-medium',
            { 'search-waiting': searchWaiting }
          ]"
        >
          <button @click="navSearch">{{ searchText }}</button>
        </div>
      </div>

      <div class="top-init" v-if="searchInit">
        <div class="trending">
          <div class="trending-title">
            <img src="@/assets/home/info.png" alt=""/>
          </div>
          <div class="trending-items">
            Search Brand by Product, CoPacker by industry. Using multiple names, Keywords by adding “and”, “or” in between the words.
          </div>
        </div>
        <div class="nums">
          <div class="num-item">
            <div class="num">
              <animate-number from="0" to="4"  :duration="1500" :formatter="productsFormatter"></animate-number>
            </div>
            <div class="line"></div>
            <div class="num-type">
              Products
            </div>
          </div>
          <div class="num-item">
            <div class="num">
              <animate-number from="0" to="100"  :duration="1500" :formatter="companyFormatter"></animate-number>

            </div>
            <div class="line"></div>
            <div class="num-type">
              Companies
            </div>
          </div>
          <div class="num-item">
            <div class="num">
              <animate-number from="0" to="100"  :duration="1500" :formatter="dailyFormatter"></animate-number>

            </div>
            <div class="line"></div>
            <div class="num-type">
              Countries
            </div>
          </div>
        </div>
      </div>
      <div class="top-init top-init-not-search-init" v-if="!searchInit">
        <div class="title">More than {{ total }} "{{ topS }}" companies available</div>
        <!--        <div class="guide">Check out how to use it below</div>-->
      </div>
    </div>
    <div class="result">
      <div class="data" v-if="!searchInit && checkValue === 0">
        <div class="have-data sc-width">
          <div class="sub-nav">
            <div class="top">
              <div class="title">Filter by</div>
              <div class="reset" @click="reset">
                <div class="icon">
                  <svg
                    width="11"
                    height="11"
                    viewBox="0 0 11 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.49683 0.500005C2.89759 0.496447 0.72912 2.48499 0.508086 5.07481C0.287053 7.66464 2.08719 9.99189 4.64943 10.4288C7.21167 10.8658 9.68139 9.26671 10.3312 6.75H9.03058C8.39482 8.54821 6.5106 9.58276 4.65212 9.15405C2.79365 8.72533 1.55302 6.96993 1.76921 5.07494C1.98541 3.17995 3.58955 1.7491 5.49683 1.75C6.4903 1.75146 7.44145 2.15237 8.1362 2.86251L6.12495 4.875H10.5V0.500005L9.03058 1.96876C8.09541 1.0279 6.82339 0.499204 5.49683 0.500005Z"
                      fill="#F57F45"
                    />
                  </svg>
                </div>
                <div class="reset-title search-button">
                  Reset Filters
                </div>
              </div>
            </div>
            <div class="company">
              <div class="company-title">Company Name</div>
              <div class="company-search">
                <div class="icon">
                  <svg
                    width="12"
                    height="11"
                    viewBox="0 0 12 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.2022 10.6005L6.69853 7.02797C5.1399 8.15763 2.99216 7.88131 1.7577 6.3923C0.523242 4.90329 0.613885 2.69834 1.96623 1.31985C3.31821 -0.059249 5.48129 -0.152055 6.94216 1.10636C8.40303 2.36477 8.67423 4.5545 7.56603 6.1436L11.0697 9.7161L10.2029 10.5998L10.2022 10.6005ZM4.56688 1.47109C3.40431 1.47082 2.40132 2.30277 2.16515 3.46324C1.92899 4.62372 2.52456 5.79377 3.59128 6.265C4.65801 6.73622 5.9027 6.37912 6.57178 5.40988C7.24085 4.44064 7.15041 3.12567 6.35521 2.2611L6.72612 2.6361L6.30801 2.2111L6.30065 2.2036C5.84194 1.73308 5.21759 1.4693 4.56688 1.47109Z"
                      fill="#828282"
                    />
                  </svg>
                </div>
                <div class="input">
                  <input
                    type="text"
                    placeholder="Search company..."
                    v-model="filter.company_name"
                  />
                </div>
              </div>
            </div>
            <div class="type">
              <div class="type-title">
                Company Type
              </div>
              <div class="items">
                <div class="item">
                  <div class="check">
                    <input
                      type="checkbox"
                      value="0"
                      id="inputId"
                      @change="checked(0)"
                      v-model="searchType"
                    />
                    <label for="inputId"></label>
                  </div>
                  <div class="name">
                    All types
                  </div>

                </div>
                <div class="item">
                  <div class="check">
                    <input
                      type="checkbox"
                      value="4"
                      id="id-end"
                      @change="checked(4)"
                      v-model="searchType"
                    />
                    <label for="id-end"></label>
                  </div>
                  <div class="name">
                    Customer(CoPacker, Brands)
                  </div>
                  <div class="count">
                    ({{ type[4] + type[8] }})
                  </div>
                </div>
                <div class="item">
                  <div class="check">
                    <input
                      type="checkbox"
                      value="3"
                      id="id-factory"
                      @change="checked(3)"
                      v-model="searchType"
                    />
                    <label for="id-factory"></label>
                  </div>
                  <div class="name">
                    Organic Factory
                  </div>
                  <div class="count">
                    ({{ type[3] }})
                  </div>
                </div>
                <div class="item">
                  <div class="check">
                    <input
                      type="checkbox"
                      value="7"
                      id="id-non-gmo"
                      @change="checked(7)"
                      v-model="searchType"
                    />
                    <label for="id-non-gmo"></label>
                  </div>
                  <div class="name">
                    Non GMO
                  </div>
                  <div class="count">
                    ({{ type[7] }})
                  </div>
                </div>
                <div class="item">
                  <div class="check">
                    <input
                      type="checkbox"
                      value="2"
                      id="id-exporter"
                      @change="checked(2)"
                      v-model="searchType"
                    />
                    <label for="id-exporter"></label>
                  </div>
                  <div class="name">
                    Foreign Exporter
                  </div>
                  <div class="count">
                    ({{ type[2] }})
                  </div>
                </div>
                <div class="item">
                  <div class="check">
                    <input
                      type="checkbox"
                      value="1"
                      id="id-importer"
                      @change="checked(1)"
                      v-model="searchType"
                    />
                    <label for="id-importer"></label>
                  </div>
                  <div class="name">
                    US Importer
                  </div>
                  <div class="count">
                    ({{ type[1] }})
                  </div>
                </div>
                <!--                <div class="item">-->
                <!--                  <div class="check">-->
                <!--                    <input-->
                <!--                      type="checkbox"-->
                <!--                      value="5"-->
                <!--                      id="id-storefront"-->
                <!--                      @change="checked(5)"-->
                <!--                      v-model="filter.type5"-->
                <!--                    />-->
                <!--                    <label for="id-storefront"></label>-->
                <!--                  </div>-->
                <!--                  <div class="name">-->
                <!--                    Storefront-->
                <!--                  </div>-->
                <!--                  <div class="count">-->
                <!--                    ({{ type[5] }})-->
                <!--                  </div>-->
                <!--                </div>-->
                <div class="item">
                  <div class="check">
                    <input
                      type="checkbox"
                      value="6"
                      id="id-global-factory"
                      @change="checked(6)"
                      v-model="searchType"
                    />
                    <label for="id-global-factory"></label>
                  </div>
                  <div class="name">
                    Global Company
                  </div>
                  <div class="count">
                    ({{ type[6] }})
                  </div>
                </div>

              </div>
            </div>

            <div class="btn search-button" @click="navSearch()">Search</div>
            <div v-if="showDownloadCsv" style="height: 0;width: 0;position: relative;top: 5px;left: 5px;">
              <download-csv
                :data = "jsonData"
                ref="downloadCsv"
                name="Maxdata.csv"
              >
                .
              </download-csv>
            </div>

            <div class="btn search-button export-button" @click="exportData()" v-if="!exporting">Export</div>
            <div class="btn search-button export-button" :class="exporting ? 'exporting-button' : ''" v-else>Exporting</div>
          </div>
          <div class="load-data" v-if="loading" style="width:calc(100% - 318px)">
            <div class="title">
              <div class="content">
                <div class="company item font-black ">Company Name</div>
                <div class="type item font-black">Company Type</div>
                <!--              <div class="product item font-black">PRODUCT</div>-->
                <!--                <div class="location item font-black">Location</div>-->
                <div class="contact item font-black">Country</div>
              </div>
            </div>
            <div class="pic"><img src="@/assets/loading.gif" /></div>
          </div>
          <div class="have-not-data" v-if="list.length === 0 && !loading" style="">
            <div class="title">
              <div class="content">
                <div class="company item font-black ">Company Name</div>
                <div class="type item font-black">Company Type</div>
                <!--              <div class="product item font-black">PRODUCT</div>-->
                <!--                <div class="location item font-black">Location</div>-->
                <div class="contact item font-black">Country</div>
              </div>
            </div>
            <div class="pic"><img src="@/assets/no-data.png" /></div>
            <div class="desc">No Data</div>
          </div>
          <div
            class="main-content"
            v-if="list.length > 0"
            style=""
          >
            <div class="title">
              <div class="content">
                <div class="company item font-black ">Company Name</div>
                <div class="type item font-black">Company Type</div>
                <!--              <div class="product item font-black">PRODUCT</div>-->
                <!--                <div class="location item font-black">Location</div>-->
                <div class="contact item font-black">Country</div>
              </div>
            </div>
            <div
              :class="['value', { valueActive: activeIndex === item._id },{ valueHide: item._source.company == '***' }]"
              v-for="item in list"
              :key="item._id"
              @mouseover="mouseOver(item._id)"
              @mouseleave="mouseLeave()"
              @click="showContacts(item)"
            >
              <div class="content-hide" v-if="item._source.company == '***'" @click="updatePackage()">
                <template v-if="item._source.hideNum == 1">
                  <div class="lock">
                    <div class="icon">
                      <template v-if="!packageLoading">
                        <img src="@/assets/data-search/lock.png" />
                      </template>
                      <template v-else>
                        <img src="@/assets/loading.gif" alt=""/>
                      </template>

                    </div>
                    <div class="word">{{upgrade_text}}</div>
                  </div>

                </template>

              </div>
              <div class="content" v-else>
                <div class="top-content">
                  <div class="company-value item" :title="item._source.company">
                    <div
                      :class="[
                      'company-name',
                      { 'show-company': activeCompany == item._id }
                    ]"
                      @mouseover="showCompany(item._id)"
                    >
                      <div v-if="item._source.is_company > 0" class=" search-button">
                        <div
                          class="contact-title"
                          @click="message(item._source.company_id)"
                        >
                          {{item._source.company}}
                        </div>
                      </div>
                      <div
                        v-else-if="item._source.website"
                        :title="item._source.website"
                        class="company-name-word"
                      >
                        {{item._source.company}}
                      </div>
                      <div v-else class="company-name-word">{{item._source.company}}</div>
                    </div>
                    <div class="company-location">
                      {{ item._source.location }}
                    </div>

                  </div>
                  <div class="type-value item" :title="item._source.type_desc">
                    <div class="type-value-word">
                      {{ item._source.type_desc }}
                    </div>
                  </div>
                  <!--              <div class="product-value item" :title="item.product_name">{{item.product_name  | ellipsis}}</div>-->
                  <!--                <div class="location-value item" :title="item.location">-->
                  <!--                  {{ item.location | ellipsis }}-->
                  <!--                </div>-->
                  <div class="contact-value item" >
                    {{item._source.country}}
                  </div>
                </div>

                <div class="bottom-content">
                  <div class="or-products" v-if="orProducts.length > 1">
                    <div class="or-item" v-for="(orItem,orIndex) in orProducts" :key="orIndex" v-if="Object.values(item._source.or_products).indexOf(orItem) > -1">
                      <div class="or-icon">
                        <img src="@/assets/data-search/check.png"/>
                      </div>
                      <div class="or-word">
                        {{orItem}}</div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div
              class="page"
              v-if="list.length > 0 && !searchInit && checkValue === 0"
            >
              <Page
                transfer
                :current="page"
                :page-size="limit"
                :total="total"
                :page-size-opts="[15, 30, 50]"
                show-elevator
                show-sizer
                show-total
                @on-change="pageChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </div>


        </div>
      </div>
      <div v-if="searchInit" class="no-data">
        <div class="steps  animate__animated animate__slideInUp">
          <div class="steps-title">Unlock MaxData's Potential in 3 Simple Steps!</div>
          <div class="steps-items">
            <div class="step-item">
              <div class="item-num">1</div>
              <div class="item-title">Search ingredients</div>
              <div class="item-content">Enter the product name to receive a complete list of relevant end-users, organic factories, foreign exporters, US importers, global factories, etc.</div>
            </div>
            <div class="step-item">
              <div class="item-num">2</div>
              <div class="item-title">Filter and Explore</div>
              <div class="item-content">
                Narrow down search results based on personalized requests. Use our optimized and categorized list to reach out to your targets.
              </div>
            </div>
            <div class="step-item">
              <div class="item-num">3</div>
              <div class="item-title">Contact and Connect</div>
              <div class="item-content">Use our categorized contact list to reach your targets.</div>
            </div>
          </div>
        </div>
        <div class="benefits   animate__animated animate__slideInUp">
          <div class="benefit">
            <div class="item item-1">
              <div class="icon">
                <img src="@/assets/data-search/benefit-1.png" />
              </div>
              <div class="word">
                Ultimate Global Sourcing Solution
              </div>
            </div>
            <div class="item item-2">
              <div class="icon">
                <img src="@/assets/data-search/benefit-2.png" />
              </div>
              <div class="word">
                Mega Database: 100K+ Companies, 4Million + Products
              </div>
            </div>
            <div class="item item-3">
              <div class="icon">
                <img src="@/assets/data-search/benefit-3.png" />
              </div>
              <div class="word">
                Get Fast, Easy, and Accurate Results
              </div>
            </div>
            <div class="item item-4">
              <div class="icon">
                <img src="@/assets/data-search/benefit-4.png" />
              </div>
              <div class="word">
                Connect Directly with Your Targets
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
    <PopLogin ref="popLogin" @register="register"></PopLogin>
    <PopPricing ref="popPricing"></PopPricing>
    <PopPackage ref="popPackage"></PopPackage>
<!--    <PopQuotation ref="popQuotation" @getSourcings="getListData"></PopQuotation>-->
<!--    <PopInquiry ref="popInquiry" @getSourcings="getListData"></PopInquiry>-->
    <PopRegisterEmail
      ref="popRegisterEmail"
      @successRegister="successRegister"
      @login="login"
    ></PopRegisterEmail>
    <Modal
      class="seller-c"
      title="Info"
      @on-cancel="cancel"
      v-model="showSellerInfo"
    >
      <div class="content">
        Seller does not have the permission.
      </div>
    </Modal>
    <div>
      <stripe-checkout
        ref="checkoutRef"
        mode="payment"
        :pk="publishableKey"
        :line-items="lineItems"
        :session-id="sessionId"
      />
      <button @click="submit" class="stripe-submit" ref="stripeSubmit" style="display: none;"></button>
    </div>
    <PaymentSuccess ref="paymentSuccess" :payment="paymentInfo"></PaymentSuccess>
    <CompanyContacts ref="companyContacts" ></CompanyContacts>
  </div>
</template>

<script>
  import api from "@/api";
  const {
    _dataSearch,
    _dataSearchExport,
    _querySourcing,
    _querySearchPromotion,_getStripeSessionId,_getPaymentSuccess
  } = api;
  import { mapState } from "vuex";
  import PopLogin from "@/components/popLogin";
  import PopPricing from "@/components/pricing";
  import PopPackage from "@/components/package";
  import moment from "moment";
  import PopInquiry from "@/components/inquiry";
  import PopQuotation from "@/components/quotation";
  import PopRegisterEmail from "@/components/popRegisterEmail";
  import { StripeCheckout } from '@vue-stripe/vue-stripe';
  import PaymentSuccess from "../components/paymentSuccess";
  import CompanyContacts from "../components/companyContacts";
  export default {
    computed: {
      ...mapState("user", ["token", "role"]),
      defaultOption() {
        return {
          step: 0.5,
          limitMoveNum: 6,
          hoverStop: true,
          direction: 1,
          openWatch: true,
          singleHeight: 0,
          waitTime: 3000
        };
      }
    },
    components: {
      PopLogin,
      PopPricing,
      PopInquiry,
      PopQuotation,
      PopRegisterEmail,
      PopPackage,StripeCheckout,
      PaymentSuccess,
      CompanyContacts
    },
    beforeCreate() {},
    data() {
      return {
        mobileWidth: window.innerWidth < 800,
        middleWidth: window.innerWidth < 1600,
        page: 1,
        limit: 30, // must in 5-20
        total: 0,
        inquiryPage: 1,
        inquiryLimit: 20,
        inquiryTotal: 0,
        quotationPage: 1,
        quotationLimit: 20,
        quotationTotal: 0,
        list: [],
        loading: false,
        s: "",
        filter: {},
        searchInit: true,
        activeIndex: null,
        showPop: true,
        type: [],
        searchType: [],
        searchLocation: [],
        topS: "",
        location: "",
        menu: false,
        showPopLogin: false,
        showSellerInfo: false,
        searchText: "Search",
        searchWaiting: false,
        activeCompany: 0,
        activeProduct: 0,
        checkValueArr: [
          { label: "Companies", checkValue: 0, value: 0 },
          { label: "Buyer", checkValue: 1, value: 0 },
          { label: "Seller", checkValue: 2, value: 0 }
        ],
        checkValue: 0,
        inquiryList: [],
        quotationList: [],
        showTip: true,
        inquiry: {},
        liquidationList: [],
        newProductList: [],
        sourcingList: [],
        promotionList: [],
        showNew: false,
        showSource: false,
        showInput: true,
        hideNum : 0,
        searchProductLabel: 'Search Brand by Product, CoPacker by industry. Using multiple names, Keywords by adding “and”, “or” in between the words.',
        showLabel: true,
        upgrade_text: "",
        orProducts: [],
        exporting: false,
        jsonData: [],
        showDownloadCsv: false,
        publishableKey: 'pk_test_51NAOQLIEjv6bUyFducwlqxF60sD3MNObCKkv6jc3sGgARx4CL1CXSZaysTzRCzrX6DMyUZgAOfhDwBiZopyQy81s00nbKCiCfW',
        loading: false,
        lineItems: [
          {
            price: 'price_1NAOQrIEjv6bUyFdfbD6tvHX', // The id of the one-time price you created in your Stripe dashboard
            quantity: 1,
          },
        ],
        sessionId: '',
        successURL: 'your-success-url',
        cancelURL: 'your-cancel-url',
        packageLoading: false,
        paymentInfo: []
      };
    },
    // created:{
    //
    // },
    mounted() {
      this.s = this.$route.query.q;
      let companyType = this.$route.query.type;
      if (typeof companyType != "undefined" && companyType != "") {
        this.checked(companyType);
        this.filter.type = companyType;
      }
      if (typeof this.s != "undefined" && this.s != "") {
        this.getData();
        this.searchProductLabel = '';
        // this.removeTyping();
      }
      var showPricing = this.$route.query.showPricing;
      if (showPricing == "true") {
        this.$refs.popPricing.showPricing = true;
      }
      if("" === this.role){
        this.upgrade_text = 'Log in to view more customers';
      }else{
        this.upgrade_text = 'Upgrade your package to view more customers';
      }
    },
    methods: {
      showContacts(item){
        if(item._source.company !== '***'){
          this.$refs.companyContacts.showContacts = true;
          this.$refs.companyContacts.getContacts(item);
        }
      },
      chooseTrending(product){
        this.s = product;
        this.getData();
        this.showLabel = false
      },
      getPaymentInfo(){
        _getPaymentSuccess()
          .then(res => {
            this.paymentInfo = res.data;
          })
          .catch(({ message }) => {
            console.log(message);
          })
          .finally(() => {
            this.$refs.paymentSuccess.show = true;
          });
      },
      submit () {
        this.$refs.checkoutRef.redirectToCheckout();
      },
      updatePackage(){
        if ("" === this.role) {
          this.$refs.popLogin.showLogin = true;
        }else{
          this.$refs.popPackage.showPackage = true;
        }
      },
      getStripeSessionId(){
        _getStripeSessionId()
          .then(res => {
            this.sessionId = res.data.id;
            this.$refs.stripeSubmit.click();
          })
          .catch(({ message }) => {
            console.log(message);
          })
          .finally(() => {
            this.packageLoading = false;
          });
      },
      productsFormatter(num) {
        return parseInt(num) + ' Million+';

      },
      companyFormatter(num) {
        return parseInt(num) + 'K+';

      },
      dailyFormatter(num) {
        return parseInt(num)+ '+';
      },
      inputSearch(){
        if(this.s != ''){
          this.searchProductLabel = '';
        }
      },
      onTyperComplete: function() {
        this.removeTyping();
      },
      removeTyping(){
        this.showInput = !this.showInput;
        this.$nextTick(function () {
          this.$refs.searchInput.focus();
        });
      },
      register() {
        this.$refs.popLogin.showLogin = false;
        this.$refs.popRegisterEmail.showLogin = true;
      },
      login() {
        this.$refs.popRegisterEmail.showLogin = false;
        this.$refs.popLogin.showLogin = true;
      },
      successRegister(token) {
        this.$refs.popRegisterSuccess.showLogin = true;
        this.$refs.popRegisterSuccess.token = token;
      },
      getListData() {},
      changeShow(type) {
        if ("new" === type) {
          this.showNew = !this.showNew;
        } else {
          this.showSource = !this.showSource;
        }
      },
      jumpMaxSource(val) {
        this.$router.push(`/trades?search_type=${val}`);
      },
      closeTip() {
        this.showTip = false;
      },
      view(e) {
        if ("" === this.role) {
          this.$refs.popLogin.showLogin = true;
          return;
        }
        const item = e.target.closest(".seamless-warp-item");
        if (item) {
          const { index } = item.dataset;
          var val = JSON.parse(index);
          if (val.type === 1) {
            this.$refs.popInquiry.showInquiry = true;
            this.$refs.popInquiry.inquiry = val;
          } else {
            this.$refs.popQuotation.showInquiry = true;
            this.$refs.popQuotation.inquiry = val;
          }

          // this.inquiry = val;
        }
      },
      companyMouseOver(e) {
        this.activeIndex = e;
      },
      companyMouseLeave() {
        this.activeIndex = null;
      },
      changeCheckValue(val) {
        this.checkValue = val;
        // this.getSourcings();
      },
      showCompany(id) {
        this.activeCompany = id;
      },
      showProduct(id) {
        this.activeProduct = id;
      },
      cancel() {
        this.showSellerInfo = false;
        return;
      },
      message(companyId) {
        // if ("" === this.role) {
        //   this.$refs.popLogin.showLogin = true;
        //   return;
        // }
        // if ("exhibitor" === this.role) {
        //   this.showSellerInfo = true;
        //   return;
        // }
        // this.$router.push("/chat?company=" + companyId);
        this.$router.push("/company/" + companyId);
      },
      menuClick(selector) {
        this.menu = !this.menu;
        console.log(selector);
      },
      jumpWebsite(url) {
        let newStr1 = url.indexOf("http  //");
        let newStr2 = url.indexOf("http");
        if (newStr1 == 0) {
          window.open("http://" + url.substr(8));
        } else if (newStr2 == -1) {
          window.open("http://" + url);
        } else {
          window.open(url);
        }
      },
      navSearch() {
        if(!this.showInput){
          this.showInput = !this.showInput;
        }else{
          if(this.s != '' && typeof this.s != 'undefined'){
            this.page = 1;
            this.getData();
          }
        }
        // this.getSourcings();
        // this.getQuotatons();
      },
      exportData(){
        if ("" === this.role) {
          this.$refs.popLogin.showLogin = true;
          return;
        }
        this.exporting = true;
        if ("" === this.s) {
          return;
        }
        _dataSearchExport({
          s: this.s,
          ...this.filter
        })
          .then(response => {
            const { data } = response;
            if(response.code == 1){
              this.$refs.popPackage.showPackage = true;
              return
            }
            this.jsonData = data;
            this.showDownloadCsv = true;
            setTimeout(() =>{
              this.$refs.downloadCsv.$el.click();
              this.showDownloadCsv = false;
            },300);
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            this.exporting = false;
          });
      },
      reset() {
        this.filter = {};
        this.getData();
      },
      checked(num) {
        num = num.toString();
        let index = this.searchType.indexOf(num);
        if (index > -1) {
          // this.searchType.splice(index, 1);
          this.searchType.filter(item => (item === num))
        } else {
          // this.searchType.push(num);
        }
        this.filter.type = this.searchType.toString();
              },
      checkedLocation(num) {
        let index = this.searchLocation.indexOf(num);
        if (index > -1) {
          this.searchLocation.splice(index, 1);
        } else {
          this.searchLocation.push(num);
        }
        this.filter.country = this.searchLocation.toString();
      },
      clearSearch() {
        this.s = "";
        this.searchInit = true;
        this.topS = this.s;
      },
      closePop() {
        this.showPop = false;
      },
      mouseOver(e) {
        this.activeIndex = e;
      },
      mouseLeave() {
        this.activeIndex = null;
      },
      viewCompany(companyId) {
        this.$router.push("/company/" + companyId);
      },
      homeWithRedirect() {
        this.$router.push(`/index`);
      },
      dataSearchWithRedirect() {
        this.$router.push(`/data-search`);
      },
      productsWithRedirect() {
        this.$router.push(`/products`);
      },
      exhibitorsWithRedirect() {
        this.$router.push(`/exhibitors`);
      },
      handleSearch() {
        this.page = 1;
        this.searchInit = false;
        this.getData();
      },

      pageChange(page) {
        this.page = page;
        this.getData();
      },

      pageSizeChange(limit) {
        this.limit = limit;
        this.getData();
      },
      inquiryPageChange(page) {
        this.inquiryPage = page;
        this.getSourcings();
      },

      inquiryPageSizeChange(limit) {
        this.inquiryLimit = limit;
        this.getSourcings();
      },
      quotationPageChange(page) {
        this.quotationPage = page;
        this.getQuotatons();
      },

      quotationPageSizeChange(limit) {
        this.quotationLimit = limit;
        this.getQuotatons();
      },
      getSearchData() {
        if ("" === this.s) {
          return;
        }
        this.loading = true;
        this.list = [];
        this.searchInit = false;
        this.topS = this.s;
        this.searchText = "Searching";
        this.searchWaiting = true;
        _dataSearch({
          page: this.page,
          limit: this.limit,
          s: this.s,
          ...this.filter
        })
          .then(response => {
            this.searchText = "Search";
            this.searchWaiting = false;
            const { data } = response;
            this.list = data[0].hits;
            this.total = data[0].total.value;
            this.type = [0,0,0,0,0,0,0,0,0];
            for(var i = 0; i < data[1].length; i++) {
              this.type[data[1][i].key] = data[1][i].doc_count;
            };
            if("" === this.role){
              this.upgrade_text = 'Log in to view more customers';
            }else{
              this.upgrade_text = 'Upgrade your package to view more customers';
            }
            this.orProducts = data[2];
          })
          .catch(err => {
            this.searchText = "Search";
            this.searchWaiting = false;
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      getData() {
        this.getSearchData();
        // this.getLiquidation();
        // this.getNewProduct();
        // this.getSourcings();
        // this.getQuotatons();
        // this.getAllPromotion();
      },
      getAllPromotion() {
        _querySearchPromotion({})
          .then(response => {
            const { data } = response;
            if (typeof data[4] !== "undefined") {
              this.liquidationList = data[4];
            }
            if (typeof data[3] !== "undefined") {
              this.newProductList = data[3];
            }
            if (typeof data[2] !== "undefined") {
              this.promotionList = data[2];
            }
            if (typeof data[1] !== "undefined") {
              this.sourcingList = data[1];
            }
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            setTimeout(() => {
              this.showNew = false;
              this.showSource = false;
            }, 500);
          });
      },
      getLiquidation() {
        _querySourcing({
          limit: 20,
          type: 4
        })
          .then(response => {
            const { data } = response;
            this.liquidationList = data.data;
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {});
      },
      getNewProduct() {
        _querySourcing({
          limit: 20,
          type: 3
        })
          .then(response => {
            const { data } = response;
            this.newProductList = data.data;
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {});
      },
      getSourcings() {
        this.loading = true;
        this.inquiryList = [];
        _querySourcing({
          page: this.inquiryPage,
          limit: this.inquiryLimit,
          // ...this.filter,
          product_name: this.s,
          type: "1"
        })
          .then(response => {
            const { data } = response;
            this.sourcingList = data.data;
            // this.inquiryTotal = data.total;
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      getQuotatons() {
        this.loading = true;
        this.quotationList = [];
        _querySourcing({
          page: this.quotationPage,
          limit: this.quotationLimit,
          // ...this.filter,
          product_name: this.s,
          type: "2"
        })
          .then(response => {
            const { data } = response;
            this.promotionList = data.data;
            // this.quotationTotal = data.total;
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    filters: {
      moment(val) {
        return moment
          .utc(val)
          .local()
          .fromNow();
      },
      ellipsis(value) {
        if (!value) return "";
        if (value.length > 35) {
          return value.slice(0, 35) + "...";
        }
        return value;
      },
      ellipsisWebsite(value) {
        if (!value) return "";
        if (value.length > 25) {
          return value.slice(0, 25) + "...";
        }
        return value;
      },
      typeEllipsis(value) {
        if (!value) return "";
        if (value.length > 15) {
          return value.slice(0, 15) + "...";
        }
        return value;
      }
    }
  };
</script>

<style lang="less" scoped>
  .have-no-data{
    width: calc(100% - 318px);
  }

  .homePage {
    background: #ffffff;
    margin-top: 77px;
  }
  .navs {
    display: flex;
    height: 84px;
    /*width:1500px;*/
    margin: 0 auto;
    align-items: center;
    .nav {
      margin: 20px;
      font-size: 20px;
      font-weight: 700;
      color: #53575c;
      line-height: 17px;
      cursor: pointer;
    }
    .home {
      margin-left: 0px;
    }
    .active {
      color: #ef842d;
      cursor: auto;
    }
  }
  .not-init-search {
    height: fit-content !important;
  }
  .search {
    height: 370px;
    background-color: #120D21;
    padding-top: 50px;
    .top {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      top: 36px;
      /*padding-top: 50px;*/
      width: 985px;
      background: #fff;
      margin: 0 auto;
      border-radius: 5px;
      .select {
        select {
          height: 75px;
          width: 200px;
          font-size: 22px;
          font-weight: 500;
          color: #53575c;
          line-height: 17px;
        }
      }
      .input {
        input {
          height: 60px;
          /*width:800px*/
          font-size: 20px;
          outline: none;
          border-right: 0px;
          padding-left: 20px;
          /*border-top-left-radius:5px;*/
          /*border-bottom-left-radius:5px;*/
          border-bottom-width: 0px;
          border-top-width: 0px;
          border-left: 0px;
        }
      }
      .icon {
        width: 40px;
        height: 60px;
        background: #ffffff;
        padding-top: 23px;
        padding-left: 20px;
        border-top-left-radius: (5px);
        border-bottom-left-radius: (5px);
        svg{
          width: 18px;
          height: 17px;
        }
        img {
        }
      }
      .icon2 {
        width: 35px;
        height: 60px;
        background: #ffffff;
        padding-top: 20px;
        svg{
          width: 22px;
          height: 21px;
        }
        img {
        }
      }
      .button {
        height: 60px;
        button {
          height: 60px;
          width: 150px;
          background-color: #FF4835;
          border-radius: 0px 5px 5px 0px;
          border: 0px;
          font-size: 18px;
          font-weight: 500;
          color: #fff;
          line-height: 20px;
          cursor: pointer;
          
        }
      }
      .search-waiting {
        opacity: 0.7;
        button{
          transition: 0.5s;
          &:after {
            content: '';
            position: absolute;
            opacity: 0;  
            top: 18px;
            right: -20px;
            transition: 0.5s;
          }

          &:hover{
            padding-right: 0;
            padding-left:0;
          }

          &:hover:after {
            opacity: 1;
            right: 0;
          }
        }
      }
    }

    .top-init {
      text-align: center;
      position: relative;
      padding-bottom: 1rem;
      top: 2rem;
      .title {
        font-weight: 600;
        font-size: 1.625rem;
        line-height: 1.4rem;
        color: #ffffff;
        margin: 20px auto 20px auto;
        width: 60rem;

      }
      .sub {
        font-size: 16px;
        line-height: 22px;
        color: #e0e0e0;
        margin-bottom: 15px;
      }
      .guide {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #f57f45;
      }
      .trending{
        display: flex;
        width: 980px;
        margin: 25px auto 30px auto;
        .trending-title{
          margin-right: 7px;
          position: relative;
          top: 3px;
          img{
            width: 17px;
            height: 17px;
          }

        }
        .trending-items{
          font-weight: 500;
          font-size: 17px;
          line-height: 19px;
          display: flex;
          align-items: center;

          color: #979797;
          text-align: left;
          .trending-item{
            font-weight: 500;
            font-size: 16px;
            line-height: 18px;
            display: flex;
            align-items: center;

            color: #ECECEC;
            cursor: pointer;
            margin-left: 5px;

          }
        }
      }
      .nums{
        display: flex;
        width: 850px;
        justify-content: space-between;
        margin: 50px auto 0 auto;

        .num-item{
          .num{
            span{
              font-weight: 700;
              font-size: 25px;
              line-height: 30px;
              /* or 100% */

              display: flex;
              align-items: center;
              justify-content: center;

              color: #FF4835;
            }


          }
          .line{
            width: 97px;
            height: 0px;

            border: 1px solid #FFFFFF;
            margin: 15px auto 10px auto;
          }
          .num-type{
            font-weight: 600;
            font-size: 18px;
            line-height: 20px;
            /* or 133% */

            display: flex;
            align-items: center;
            justify-content: center;

            color: #FFFFFF;

          }
        }
      }
    }
    .top-init-not-search-init {
      /*top: 60px;*/
      margin-top: 4rem;
      top: 0;
      .title{
        margin-top: 40px;
        font-size: 22px;
      }
    }
  }
  .load-data {
    .title {
      .company {
        width: 550px;
        padding-left: 40px;
      }
      .type {
        width: 355px;
      }
      .product {
        width: 500px;
      }
      .location {
        width: 150px;
      }
      .contact {
        /*width: 150px;*/
      }
    }
  }
  .result {
    text-align: center;
    padding-bottom: 50px;
    .data {
      margin: 0 auto 50px auto;
      .title {
        background-color: #f2f2f2;
        .content {
          display: flex;
          margin: 0 auto;
          justify-content: normal;
          border-bottom: 1px solid #d0d0d0;
          .item {
            height: 60px;
            font-size: 20px;
            font-weight: 600;
            text-align: left;
            padding-top: 15px;
          }
          .company {
            width: 550px;
            padding-left: 40px;
          }
          .type {
            width: 300px;
            padding-left: 10px;
          }
          .product {
            width: 550px;
          }
          .location {
            width: 150px;
          }
          .contact {
            width: calc(100% - 850px);
          }
        }
      }
      .value {
        cursor: pointer;
        .content {

          margin: 0 auto;
          /*justify-content: space-between;*/

          border-bottom: 1px solid #e9e9e9;
          .top-content{
            display: flex;
            justify-content: space-between;
          }
          .bottom-content{
            .or-products{
              display: flex;
              justify-content: flex-start;
              margin-top: 0;
              flex-flow: wrap;
              margin-left: 2.5rem;
              margin-bottom: 5px;
              .or-item{
                display: flex;
                margin-right: 20px;
                .or-icon{
                  margin-right: 5px;
                  img{
                    width: 14px;
                    height: 14px;
                    position: relative;
                    top: 1px;
                  }


                }
                .or-word{
                  font-size: 14px;
                  color: #8A8A8A;

                }
                .or-word-close{
                  color: #A3A3A3;
                }
              }
            }
          }
          .item {
            height: auto;
            font-size: 18px;
            text-align: left;
            /*padding-top: 15px;*/
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-top: 10px;
            padding-bottom: 10px;
          }
          .company-value {
            width: 550px;
            padding-left: 40px;
            .name {
              max-width: 420px;
              .company-name {
                width: 330px;
              }
            }
            /*display: flex;*/
            .jump {
              margin: 3px 0 0 8px;
            }
            .company-name {
              font-size: 18px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              .company-name-word{
                font-size: 1.1rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-weight: 500;
              }
            }
            .show-company {
              white-space: normal !important;
            }
            .company-location {
              color: #a3a3a3;
              font-size: 14px;
            }

          }
          .item-company {
            .company-value-link {
              display: flex;
              cursor: pointer;
              color: #ef7f21;
            }
          }
          .type-value {
            width: 300px;
            padding-left: 10px;
            .type-value-word{
              font-size: 17px;
              background: #efefef;
              padding: 5px 10px;
              border-radius: 5px;
              width: fit-content;
            }
          }
          .product-value {
            width: 500px;
          }
          .location-value {
            width: 250px;
          }
          .contact-value {
            width: calc(100% - 850px);
            .chat {
              display: flex;
              width: 130px;
              height: 30px;
              background: #f57f45;
              border-radius: 4px;
              justify-content: center;
              padding-top: 5px;
              .icon {
                margin-top: 2px;
                margin-right: 5px;
              }
              .contact-title {
                font-weight: 600;
                font-size: 12px;
                line-height: 22px;
                color: #ffffff;
              }
            }
          }
        }
      }
      .valueActive {
        background: #f2f2f2;
      }
      .valueHide{
        background: #CFCFCF;
        /* Note: backdrop-filter has minimal browser support */
        .content-hide{
          cursor: pointer;
          .lock{
            height: 300px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .icon{
              img{
                width: 26px;
                height: 36px;
              }
            }
            .word{
              color: #FFF;
              margin-top: 20px;
              font-size: 22px;
              font-weight: 600;
            }
          }
        }
        .content{
          border-bottom: none;
          color: rgba(27, 27, 27, 0.3);
        }
      }
      .ou {
        background: #f2f2f2;
      }
    }

    .no-data {
      .steps{
        margin: 0 auto;
        width: 1200px;
        .steps-title{
          font-weight: 600;
          font-size: 30px;
          line-height: 40px;
          /* identical to box height, or 67% */

          display: flex;
          align-items: center;
          justify-content: center;

          color: #000000;
          margin: 80px auto;
        }
        .steps-items{
          display: flex;
          width: 1200px;
          justify-content: space-between;
          padding-top: 20px;
          .step-item{
            width: 400px;
            .item-num{
              font-weight: 600;
              font-size: 80px;
              line-height: 20px;
              /* or 25% */

              display: flex;
              align-items: center;
              justify-content: center;

              color: #FF4835;
              margin-bottom: 45px;

            }
            .item-title{
              font-weight: 600;
              font-size: 20px;
              font-size: 20px;
              line-height: 24px;
              display: flex;
              align-items: center;
              justify-content: center;

              color: #000000;
              margin-bottom: 20px;
              margin-top: 20px;

            }
            .item-content{
              font-weight: 400;
              font-size: 16px;
              line-height: 20px;
              /* or 125% */

              display: flex;
              align-items: center;
              justify-content: center;

              color: #656565;
              width: 300px;
              margin: 0 auto;

            }
          }
        }
      }
      .benefits{
        display: flex;
        margin-top: 100px;
        .benefit{
          display: flex;
          justify-content: space-between;
          width: 1200px;
          margin:  0 auto;
          .item{
            width: 250px;
            height: 235px;
            border-radius: 20px;
            .word{
              font-weight: 600;
              font-size: 16px;
              line-height: 20px;
              /* or 132% */

              display: flex;
              justify-content: center;

              color: #000000;
              margin-top: 45px;
              text-align: left;
              margin-left: 30px;
              padding-right: 20px;

            }
            .icon{
              display: flex;

              margin: 10px 0 0 30px;
              img{
                width: 30px;
                height: 30px;
                margin-top: 30px;
              }

            }
          }
          .item:hover{
            .icon{
              filter: invert(100%);
            }
            .word{
              filter: invert(100%);
            }
          }
          .item-1{
            background-image: linear-gradient(130deg,#c3caf6,#ede0f9);
            transition: background-color .2s;
          }
          .item-1:hover{
            background-image: linear-gradient(130deg,#3f55dd,#9d4ee5);
          }
          .item-2{
            background-image: linear-gradient(130deg,#e1f8ff,#cdffe4)
          }
          .item-2:hover{
            background-image: linear-gradient(130deg,#55b8d6,#47e48f)
          }
          .item-3{
            background-image: linear-gradient(130deg,#f7eefe,#fae9ec)
          }
          .item-3:hover{
            background-image: linear-gradient(130deg,#ca86ff,#ff6580)
          }
          .item-4{
            background-image: linear-gradient(130deg,#e0fbfe,#ebf0f2)
          }
          .item-4:hover{
            background-image: linear-gradient(130deg,#6df0ff,#00a1e2)
          }
        }
      }
      img {
        margin-top: 50px;
      }
      .title {
        width: 900px;
        height: 30px;
        font-size: 28px;
        font-weight: 500;
        color: #252729;
        line-height: 38px;
        margin: 62px auto 0 auto;
      }
      .tips {
        /*height: 200px;*/
        font-size: 22px;
        font-weight: 500;
        color: #999999;
        line-height: 38px;
        margin: 23px auto 0 auto;
      }
      .menu {
        display: flex;
        justify-content: center;
        margin-top: 46px;
        .menu-item {
          .content {
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            /* or 137% */

            display: flex;
            align-items: center;
            text-align: center;

            /* Gray 2 */

            color: #4f4f4f;
          }
          .line {
          }
        }
        .menu-left {
          margin-right: 48px;
        }
        .active-item {
          .content {
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            /* or 137% */

            display: flex;
            align-items: center;
            text-align: center;

            /* Primary */

            color: #f57f45;
          }
          .line {
            height: 0px;
            border: 2px solid #f57f45;
            margin-top: 8px;
          }
        }
      }
    }
  }
  .filter-search {
    background: #fff;
    /*border-bottom: 1px solid #ccc;*/
    padding: 16px 40px;
    margin: 0 auto;
    .search-box {
      display: flex;
      align-items: center;
      height: 35px;
      // width: 600px;
      .input-box {
        position: relative;
        flex: 1;
        // width: 600px;
        height: 100%;
        background: #fff;
        line-height: initial;
        padding-left: 40px;
        padding-right: 20px;
        border: 1px solid #dfdfdf;
        border-right: none;
        border-top-left-radius: 40px;
        border-bottom-left-radius: 40px;
        .search-icon {
          position: absolute;
          top: 7px;
          left: 10px;
          font-size: 20px;
          color: #888;
        }
        input {
          width: 100%;
          height: 35px;
          outline: none;
          background: transparent;
          border: none;
          color: #444;
          caret-color: #888;

          &::-webkit-input-placeholder {
            color: #bdcada;
          }
          &:-moz-placeholder {
            color: #bdcada;
          }
          &::-moz-placeholder {
            color: #bdcada;
          }
          &:-ms-input-placeholder {
            color: #bdcada;
          }
        }
      }
      .tit {
        padding: 0 10px;
        margin-left: 10px;
        font-weight: bold;
        font-size: 18px;
        &.first {
          padding-left: 0;
          margin-left: 0;
        }
      }
      .btn {
        cursor: pointer;
        background: #fff;
        color: @primary-color;
        border: 1px solid @primary-color;
        border-top-right-radius: 40px;
        border-bottom-right-radius: 40px;
        padding: 0px 20px;
        display: flex;
        align-items: center;
        justify-self: center;
        line-height: initial;
        height: 100%;
      }

      .type-wrap {
        position: absolute;
        right: 20px;
        color: #ffca94;
        font-size: 20px;
        cursor: pointer;
        /deep/ .ivu-icon-ios-list {
          position: relative;
          top: 3px;
          font-size: 32px;
          font-weight: bold;
        }
        .cur {
          color: @primary-color;
        }
      }
    }
    .words {
      .info-title {
        color: #333;
        font-size: 1.2em;
        font-weight: bold;
        margin-bottom: 10px;
      }
      .terms-box {
        display: flex;

        .terms {
          color: @primary-color;
          border: 1px solid @primary-color;
          border-radius: 4px;
          padding: 4px 10px;
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }
  }
  .search-box .filter {
    display: flex;
    justify-content: space-around;
    width: 180px;
    height: 38px;
    /*background: #EF842D;*/
    border: 1px solid #ef842d;
    border-radius: 5px;
    padding: 10px 0 0 0px;
    font-size: 18px;
    font-weight: 500;
    color: #ef842d;
    line-height: 17px;
    cursor: pointer;
    margin-left: 30px;
    /* margin: 20px 0 0 60px; */
    position: relative;
    bottom: 3px;
  }
  .load-data {
    .pic {
      margin-top: 70px;
      img {
        width: 60px;
        height: 60px;
      }
    }
  }
  .have-not-data {
    width: calc(100% - 400px);
    /*border-right: 1px solid #C4C4C4;*/
    box-shadow: 4Px 0 10Px rgba(0, 0, 0, 0.25);
    .title {
      .company {
        width: 550px;
        padding-left: 40px;
      }
      .type {
        width: 250px;
      }
      .product {
        width: 500px;
      }
      .location {
        width: 250px;
      }
      .contact {
        width: 200px;
      }
    }
    text-align: center;
    .pic {
      margin-top: 120px;
      img {
        width: 82px;
        height: 59px;
      }
    }
    .desc {
      font-size: 16px;

      font-weight: 500;
      color: #999999;
      line-height: 18px;
      margin-top: 20px;
    }
  }
  .only-benefits {
    height: 640px !important;
  }
  .pop {
    /*width: 900px;*/
    /*height: 1120px;*/
    background: #ffffff;
    /*border-radius: 10px;*/
    /*position: absolute;*/
    /*left: 50%;*/
    /*margin-left: -450px;*/
    top: 300px;
    .top {
      .title {
        font-weight: 600;
        font-size: 22px;
        line-height: 22px;
        margin: 19px auto 0 auto;
        /*position: relative;*/
        /*left: 315px;*/
        display: inline-block;
      }
      .close {
        img {
          width: 14px;
          height: 14px;
        }
        display: inline-block;
        float: right;
        margin: 10px 10px 0 0;
      }
    }
    .steps {
      margin-top: 40px;
      .easy-steps {
        width: 820px;
        margin: 0 auto;
        text-align: left;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        /* or 137% */

        display: flex;
        align-items: center;

        /* Black */

        color: #222222;
        margin-bottom: 32px;
      }
      .number {
        display: flex;
        justify-content: center;
        .item-number {
          width: 32px;
          height: 32px;
          background: rgba(245, 124, 0, 0.3);
          border-radius: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 20px;
          margin-right: 20px;
        }
        .item-line {
          width: 260px;
          height: 0px;
          border: 1px solid #fcd8b3;
          top: 15px;
          position: relative;
        }
      }
      .name {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        .item {
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
          color: #4f4f4f;
        }
        .name-first {
          margin-right: 165px;
        }
        .name-second {
          margin-right: 165px;
        }
      }
      .pics {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        .item {
          width: 218px;
          height: 158px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .item-left {
          margin-right: 100px;
        }
      }
    }
    .benefits-dividor {
      height: 0px;
      left: 11px;
      top: 1122px;

      /* Gray 5 */

      border: 1px solid #e0e0e0;
      margin-top: 70px;

      margin-bottom: 10px;
    }
    .benefits {
      width: 860px;
      margin: 0 auto;
      .left-item {
        margin-right: 30px;
      }
      .b-item {
        display: flex;
        justify-content: space-between;
        .item {
          width: 50%;
          margin-top: 55px;
          .pic {
            text-align: left;
          }
          .b-title {
            font-weight: 600;
            font-size: 20px;
            line-height: 26px;
            /* or 130% */

            display: flex;
            align-items: center;

            /* Gray 2 */

            color: #4f4f4f;
            width: 380px;
            text-align: left;
            margin: 12px 0 16px 0;
          }
          .b-content {
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            /* or 150% */

            display: flex;
            align-items: center;

            /* Gray 2 */

            color: #4f4f4f;
            width: 380px;
            text-align: left;
          }
        }
      }
    }
    .got {
      width: 160px;
      height: 48px;
      background: #f57c00;
      box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      margin: 60px auto 0 auto;
      font-weight: 600;
      font-size: 20px;
      line-height: 48px;
      text-align: center;
      color: #f2f2f2;
    }
  }
  .have-data {
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    .sub-nav {
      width: 370px;
      height: fit-content;
      background: #ffffff;
      box-shadow: 4px 0px 10px rgba(0, 0, 0, 0.25);
      padding-left: 34px;
      padding-right: 27px;
      padding-bottom: 20px;
      .top {
        display: flex;
        justify-content: space-between;
        margin-top: 17px;
        margin-bottom: 20px;
        .title {
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          color: #3b3d43;
          margin-bottom: 9px;
          background: #fff;
        }
        .reset {
          display: flex;
          /*align-items: center;*/
          .icon {
            /*padding-top: 3px;*/
            svg{
              width: 11px;
              height: 11px;
            }
          }
          .reset-title {
            font-size: 12px;
            line-height: 18px;
            color: #f57f45;
            font-weight: bold;
            padding-top: 3px;
            padding-left: 3px;
          }
        }
      }
      .company {
        .company-title {
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          display: flex;
          align-items: center;
          text-transform: capitalize;
          color: #828282;
          margin-bottom: 9px;
        }
        .company-search {
          display: flex;
          .icon {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            background: #f2f2f2;
            padding-top: 8px;
            padding-left: 5px;
          }
          .input {
            input {
              width: 290px;
              height: 40px;
              background: #f2f2f2;
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
              border: none;
              outline: none;
              font-size: 12px;
              padding-left: 2px;
            }
          }
        }
      }
      .type {
        .type-title {
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          display: flex;
          align-items: center;
          text-transform: capitalize;
          color: #828282;
          text-align: left;
          margin-top: 16px;
          margin-bottom: 9px;
        }
        .items {
          .item {
            display: flex;
            margin: 4px auto;
            .check {
              input {
                display: none;
              }
              label {
                display: inline-block;
                width: 20px;
                height: 20px;
                border-radius: 5px;
                border: 2px solid #333333;
                position: relative;
                cursor: pointer;
              }
              label::before {
                display: inline-block;
                content: " ";
                width: 12px;
                border: 2px solid #fff;
                height: 4px;
                border-top: none;
                border-right: none;
                transform: rotate(-45deg);
                top: 5px;
                left: 3px;
                position: absolute;
                opacity: 0;
              }
              input:checked + label {
                background: #f57f45;
                border: 2px solid #f57f45;
              }
              input:checked + label::before {
                opacity: 1;
                transform: all 0.5s;
              }
            }
            .name {
              font-size: 16px;
              line-height: 18px;
              text-align: center;
              color: #333333;
              flex: none;
              order: 1;
              flex-grow: 0;
              margin: 0 10px 0 10px;
              .sub-name{
                font-size: 14px;
                position: relative;
                right: 15px;
                margin-bottom: 5px;

              }
            }
            .count {
              font-size: 12px;
              line-height: 18px;
              text-align: center;
              color: #828282;
              flex: none;
              order: 1;
              flex-grow: 0;
              /*margin: 0px 16px;*/
            }
          }
        }
      }
      .location {
        .location-title {
          font-weight: 600;
          font-size: 10px;
          line-height: 24px;
          display: flex;
          align-items: center;
          text-transform: capitalize;
          color: #828282;
          text-align: left;
          margin-top: 16px;
        }
        .items {
          .item {
            display: flex;
            margin: 4px auto;
            .check {
              input {
                display: none;
              }
              label {
                display: inline-block;
                width: 20px;
                height: 20px;
                border-radius: 5px;
                border: 2px solid #333333;
                position: relative;
                cursor: pointer;
              }
              label::before {
                display: inline-block;
                content: " ";
                width: 12px;
                border: 2px solid #fff;
                height: 4px;
                border-top: none;
                border-right: none;
                transform: rotate(-45deg);
                top: 5px;
                left: 3px;
                position: absolute;
                opacity: 0;
              }
              input:checked + label {
                background: #f57f45;
                border: 2px solid #f57f45;
              }
              input:checked + label::before {
                opacity: 1;
                transform: all 0.5s;
              }
            }
            .name {
              font-size: 14px;
              line-height: 18px;
              text-align: center;
              color: #333333;
              flex: none;
              order: 1;
              flex-grow: 0;
              margin: 0px 10px 0 10px;
            }
            .count {
              font-size: 12px;
              line-height: 18px;
              text-align: center;
              color: #828282;
              flex: none;
              order: 1;
              flex-grow: 0;
              /*margin: 0px 16px;*/
            }
          }
        }
      }
      .btn {
        width: 305px;
        height: 46px;
        background: #f99d20;
        border-radius: 6px;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        color: #ffffff;
      }
      .export-button{
        border: 1px solid #f99d20;
        background: #fff;
        color: #f99d20;
        margin-top: 10px;
        z-index: 99;
      }
      .exporting-button{
        cursor: initial;
      }
    }
  }
  .type-menu {
    margin: 0 auto;
    padding: 20px 0 30px 0;
    .left {
      display: flex;
    }
    .item {
      margin-right: 25px;
      .value {
        font-size: 18px;
      }
      .value-active {
        color: #f57f45;
        font-weight: 600;
      }
      .line {
        height: 2px;
        background-color: #f57f45;
        position: relative;
        top: 11px;
      }
    }

    .alert {
      position: relative;
      width: 0;
      height: 0;
      bottom: 62px;
      .right {
        width: 287px;
        font-size: 14px;
        height: 56px;

        /* Grays/Casper/CA-700 */

        background: #334155;
        border-radius: 8px;

        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 1;
        color: #fff;
        display: flex;
        justify-content: left;
        align-items: center;
        padding: 10px;
      }
      .alert-left {
        position: relative;
        right: 8px;
        top: 52px;
      }
      .close-tip {
        bottom: 53px;
        position: relative;
        left: 260px;
      }
    }
  }
  .inquiry {
    margin: 0 auto;
  }
  .list-table {
    .title {
      display: flex;
      height: 42px;
      background: #f2f2f2;
      border-radius: 5px;
      justify-content: space-between;
      font-size: 18px;

      font-weight: 500;
      color: #757575;
      line-height: 19px;
      .item {
        font-size: 16px;
        display: flex;
        justify-content: left;
        align-items: center;
        padding-left: 40px;
      }
      .product {
        width: 400px;
      }
      .attendee {
        width: 300px;
      }
      .size {
        width: 300px;
      }
      .status-col {
        width: 150px;
      }
      .date {
        width: 200px;
      }
      .document {
        width: 300px;
      }
    }
    .content {
      /*display: flex;*/
      .item {
        height: 60px;
        /*padding:0 30px;*/
        .top {
          display: flex;
          height: 60px;
          justify-content: space-between;
          border-bottom: 1px solid #ececec;
          div {
            display: flex;
            justify-content: left;
            align-items: center;
          }
          /*padding-top: 30px;*/
          /*div {*/
          /*  font-size: 16px;*/
          /*  */
          /*  font-weight: 500;*/
          /*  color: #323538;*/
          /*  line-height: 19px;*/
          /*  padding-left: 20px;*/
          /*}*/
          .size {
            padding-left: 40px;
          }
          .status-col {
            width: 150px;
            padding-left: 40px;
          }
          .product {
            width: 400px;
            .main-name {
            }
            .by-name {
              font-size: 12px;

              font-weight: 400;
              color: #999999;
              line-height: 19px;
            }
          }
          .attendee {
            width: 300px;
            padding-left: 40px;
          }
          .size {
            width: 300px;
            padding-left: 40px;
          }
          .date {
            width: 200px;
            padding-left: 40px;
          }
          .document {
            width: 300px;
          }
        }
        .divided {
          /*width: 1440px;*/
          height: 1px;
          background: #cccccc;
        }
        .bottom {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
        }
      }
      .active {
        background: #f2f0f9;
      }
    }
  }
  .loading-pic {
    margin-top: 40px;
    img {
      width: 60px;
      height: 60px;
    }
    text-align: center;
  }
  .page {
    margin: 30px 0;
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }
  .empty {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .status {
    width: 90px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    justify-content: center !important;
    color: #fff;
    font-size: 13px;
    align-items: center;
  }
  .status-progress {
    background: #11828f;
  }
  .status-expired {
    background: #c0399f;
  }
  .status-completed {
    background: #925acf;
  }
  .left-content {
    margin: 20px 0 0 0;
    position: relative;
    right: 34px;
    /*top: 30px;*/
    .item {
      border: 1px solid #c4c4c4;
      border-radius: 10px;
      width: 318px;
      .item-top {
        background-image: url("../assets/data-search/sourcing.jpg");
        width: 318px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: 700;
        color: #fff;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        background-size: 100%;
        .up {
          position: relative;
          top: 3px;
          left: 60px;
        }
      }
      .item-content {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        .detail:hover {
          background: #f2f0f9;
        }
        .detail {
          padding-top: 3px;
          cursor: pointer;
          .product-name {
            display: flex;
            justify-content: space-between;
            div {
              font-size: 15px;
              color: #1b2559;
              text-align: left;
              font-weight: 700;
              /*max-width: 250px;*/
            }
            .right {
            }
          }
          .bottom {
            display: flex;
            justify-content: space-between;
            position: relative;
            bottom: 3px;
            .left {
              color: #a3aed0;
              font-size: 13px;
            }
            .right {
              color: #a3aed0;
              font-size: 13px;
            }
          }
        }
      }
      .bottom-menu {
        display: flex;
        justify-content: right;
        margin-top: 20px;
        margin-bottom: 15px;
        padding-left: 10px;
        padding-right: 10px;
        .left-menu {
          border: 1px solid #6c6c6c;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          width: 110px;
          height: 30px;
          font-size: 12px;
        }
        .right-menu {
          display: flex;
          padding-top: 3px;
          .word {
            color: #311996;
            font-weight: 700;
            font-size: 13px;
            padding-right: 2px;
            padding-top: 2px;
          }
        }
      }
      .no-data {
        .pic {
          img {
            margin-top: 20px;
            height: 50px;
            width: 50px;
          }
        }
      }
    }
    .item-order {
      margin-top: 20px;
    }
  }
  .steps-content {
    .step {
      width: 860px;
      margin: 0 auto;
      display: flex;
      .left {
        .step-line {
          width: 1px;
          height: 65px;
          background: rgba(245, 124, 0, 0.3);
          margin: 10px auto;
        }
        .item-number {
          width: 32px;
          height: 32px;
          background: rgba(245, 124, 0, 0.3);
          border-radius: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 20px;
          margin-right: 20px;
        }
      }
      .right {
        .step-title {
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
          color: #4f4f4f;
          display: flex;
          justify-content: left;
          margin-bottom: 10px;
        }
        .step-body {
          font-weight: 500;
          font-size: 15px;
          text-align: left;
        }
      }
    }
  }
  .auto-typing{
    width: 670px;
    background: #fff;
    height: 60px;
    padding-top: 15px;
    padding-left: 10px;
    cursor: pointer;
  }
  .contact-value{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }
  .show-product{
    white-space: normal !important;
  }
  .search-button{
    color: #f99d20;
    font-weight: 600;
  }
  .top .input{
    background: #fff;
    border: 1px solid #fff;
    position: relative;
    overflow: hidden;
    height: 60px;
    label{
      color: #707070;
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
      display: block;
      width: 100%;
      height: 100%;
      line-height: 60px;
      white-space: nowrap;
      animation: moving 10s linear infinite paused;
    }
    input{
      background: transparent;
      width: 760px;
      border: none;
      outline: none;
      height: 100%;
      margin-bottom: 10px;
      padding: 0 .5rem;
      position: relative;
      z-index: 2;
      &:focus{
        ~ label{
          visibility: hidden;
        }
      };
      &:not(:focus){
        ~ label{
          visibility: visible;
          animation-play-state: running
        }
      };
      &:not([value=""]){
        ~ label{
          visibility: visible;
          animation-play-state: running
        }
      }
    }
  }
  @keyframes moving{
    0% {  transform: translateX(10%)}
    100% {  transform: translateX(-40%)}
  }
  .main-content{
    width: calc(100% - 400px);
    /*border-right: 1px solid #C4C4C4;*/
    box-shadow:4Px 0 10Px rgba(0,0,0,0.25)
  }
</style>
<style lang="less">
  .ivu-select-item {
    font-size: 16px !important;
  }
  .ivu-select-input {
    font-size: 16px;
  }
  .ivu-input {
    font-size: 16px;
  }
  .auto-typing{
    .vue-typer .right{
      font-size: 20px;
    }
  }
</style>
